/**
 * https.js
 * 封裝axios，設置攔截器，統一管理錯誤
 */
import axios from 'axios';
// import router from "@/router";
// import qs from "qs";
import { tip, toLogin, to403Page, decodeApiResponse } from './utils.js';
// import { BIconLayoutTextWindowReverse } from "bootstrap-vue";

// 新建axios實例(instance)，設置攔截器
let instance = null;

/**
 * setInstance 初始化 axios instance
 * @param string proxy  代理伺服器名稱，['/crmartapi']/['martapi']，參考 vue.config.js的 devServer.proxy
 */
const setInstance = (proxy) => {
  // console.log("[apis][https] setInstance proxy: [" + proxy + "]");
  // console.log(
  //   "[" +
  //     process.env.NODE_ENV +
  //     "]baseUrl: " +
  //     process.env.VUE_APP_BASE_URL +
  //     proxy
  // );
  // instance = axios.create({
  //   baseURL: process.env.VUE_APP_BASE_URL + proxy,
  // });
  // create instance for proxy
  let _url = '';
  // console.log("32，baseUrl: " + _url);
  if (process.env.NODE_ENV === 'development') {
    _url = process.env.VUE_APP_BASE_URL + proxy;
  } else {
    switch (proxy) {
      case '/pfwayapi':
        _url = process.env.VUE_APP_PFWAYAPI_URL;
        break;
      default:
        console.log('get baseUrl fail with proxy: ' + proxy);
        return Promise.reject(new Error('get baseUrl fail with proxy: ' + proxy));
    }
  }
  // console.log('48，baseUrl: ' + _url);

  instance = axios.create({
    baseURL: _url,
    timeout: 20000 // 20sec
  });

  // 攔截器-request
  instance.interceptors.request.use(
    (config) => {
      // console.log(
      //   '[apis][https] instance.interceptors.request ... config ... '
      // );
      // console.log(localStorage);
      let _store = JSON.parse(localStorage.getItem(process.env.VUE_APP_USER));
      _store = !_store ? {} : _store;
      console.log(_store);
      const token = _store.accessToken != null ? _store.accessToken : null;
      // console.log('access_token: ' + token);
      if (token) {
        config.headers = {
          Accept: 'application/json',
          Authorization: token
        };
      }
      // config.data = qs.stringify(config.data);

      // console.log(config);
      return config;
    },
    (error) => {
      // console.log("[apis][https] instance.interceptors.request ... error ... ");
      // console.log(error);
      return Promise.reject(error);
    }
  );

  // 攔截器-response
  instance.interceptors.response.use(
    (response) => {
      // console.log(
      //   '[apis][https] instance.interceptors.response ... response ... '
      // );
      // console.log(response);
      return decodeApiResponse(response);
    },
    (error) => {
      // console.log(error);
      const { response } = error;
      // console.log("[apis][https] instance.interceptors.response ... error ... ");
      if (response) {
        // 成功發出請求且收到resp，但有error
        const _response = { status: response.status, msg: response.error };
        if (response.data != null) {
          _response.status =
            response.data.status != null
              ? response.data.status
              : _response.status;
          _response.msg =
            response.data.message != null
              ? response.data.message
              : _response.msg;
        }
        errorHandle(_response.status, _response.msg);
        return Promise.reject(error);
      } else {
        // // 成功發出請求但沒收到resp
        // if (!BIconLayoutTextWindowReverse.navigator.onLine) {
        //   tip("[apis][https] 網路出了點問題，請小夥伴重新連線受刷新網頁");
        // } else {
        //   // 可能是跨域，或是程式問題
        //   return Promise.reject(error);
        // }
        return Promise.reject(error);
      }
    }
  );
};

/**
 * 請求失敗，統一處理
 * @param {Number} status 請求失敗的狀態碼
 */
const errorHandle = (status, msg) => {
  // console.log("[apis][https] errorHandle ...");
  switch (status) {
    case 400:
      tip(msg);
      break;

    case 401:
      tip('登入過期，請重新登入');
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;

    case 403: // 權限不足
      to403Page();
      break;

    case 404:
      tip(msg);
      break;

    default:
      console.log(
        '[apis][https] resp沒有攔截到的錯誤，status: ' +
        status +
        '，msg: ' +
        msg
      );
  }
};

/**
 * 最後封裝請求方法，並使用 export default導出函數
 * @param string method
 * @param string url    (不含 http://XXXX)
 * @param mixed data    api入參
 * @param string proxy  預設:'/crmartapi'，指定proxy，代理到不同的api url(定義在vue.config.js的 devServer.proxy)
 * */
export default function (method, url, data = null, proxy = '') {
  // console.log("[apis][https] default ...... ");
  // console.log({
  //   method: method,
  //   url: url,
  //   data: data,
  //   proxy: proxy,
  //   accessToken: localStorage.getItem('accessToken')
  // });
  // set axios instance
  proxy = proxy === '' ? process.env.VUE_APP_PROXY : proxy;
  setInstance(proxy);

  // run method
  method = method.toLowerCase();
  let result = null;
  switch (method) {
    case 'post':
      // console.log('[https][post] ...');
      // return instance.post(url, data);
      result = instance.post(url, data);
      break;
    case 'get':
      // console.log('[https][get] ...');
      // return instance.get(url, { params: data });
      result = instance.get(url, { params: data });
      break;
    case 'delete':
      // console.log('[https][delete] ...');
      // return instance.delete(url, { params: data });
      result = instance.delete(url, { params: data });
      break;
    case 'put':
      // console.log('[https][put] ...');
      // return instance.put(url, data);
      result = instance.put(url, data);
      break;
    default:
      // console.log('[https][default]] ...');
      console.error('未知的method: ' + method);
      return false;
  }
  // console.log(result);
  return result;
}
