import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import DatetimePicker from 'vuetify-datetime-picker';
// import '/vuetify-datetime-picker/src/stylus/main.styl'; // optional

Vue.use(DatetimePicker);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
