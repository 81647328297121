/**
 * [oauth module] actions
 */
import router from '@/router';
// import * as types from "@/store/mutations_type";
import {
  OAUTH_SET_PROCESS,
  OAUTH_SET_TOKENS,
  OAUTH_SET_LOGGER,
  OAUTH_CLEAR_LOGGER
} from '@/store/mutations_type';
import { apiOAuthPostTokens, apiOAuthGetProfile } from '@api/oauth.js';

export default {
  /**
   * [setLoginProcess] 變更登入處理狀態
   * @param {*} param
   */
  setLoginProcess ({ commit }, param) {
    commit(OAUTH_SET_PROCESS, param);
  },

  /**
   * [setLoginTokens] 設定登入成功取得的 OAuth Tokens
   * @param {*} param
   */
  setLoginTokens ({ commit }, param) {
    commit(OAUTH_SET_TOKENS, param);
  },

  /**
   * [setLogger] 設定登入成功的 用戶資料
   * @param {*} param
   */
  setLogger ({ commit }, param) {
    commit(OAUTH_SET_LOGGER, param);
  },

  /**
   * [clearLogger] 登出時，清除登入所有相關資料
   */
  clearLogger ({ commit }) {
    commit(OAUTH_CLEAR_LOGGER);
  },

  /**
   * [doLogin] 登入作業
   * @param {*} param
   *              ['caller']: 身分，user / member
   *              ['email']: 帳號(電子郵件)
   *              ['password']: 密碼
   *              ['remember']: 是否儲存登入狀態
   *              ['redirect']: 登入成功傳址到指定url
   */
  async doLogin ({ dispatch, state }, param) {
    const _errCode = {
      101: 'OAuth 登入失敗',
      102: 'empty tokens',
      103: '取得用戶資料失敗',
      104: '取得用戶資料不正確',
      105: '登入程序發生異常'
    };
    param.action = 'start';
    // console.log('[setLoginProcess] ...' + param.action);
    await dispatch('setLoginProcess', param); // 登入處理開始
    console.log('[apiOAuthPostTokens] param:');
    console.log(param);

    let fail = {};
    let result = null;
    try {
      // axios api
      result = await apiOAuthPostTokens(param);
      // console.log(result);
      // 失敗
      if (!result || !result.status) {
        fail = { action: 'error', status: 101, msg: _errCode[101], data: result };
        await dispatch('setLoginProcess', fail);
        // alert(`登入失敗，錯誤訊息: [${fail.status}] ${fail.msg}`);
        return false;
      }
      if (!result.data || !result.data.access_token) {
        fail = { action: 'error', status: 102, msg: _errCode[102], data: result };
        await dispatch('setLoginProcess', fail);
        return false;
      }
      // 成功 - 儲存 tokens by mutations
      await dispatch('setLoginTokens', result.data);
      // 取得登入用戶資訊 by axios api
      result = await apiOAuthGetProfile();
      // 失敗
      if (!result || !result.status) {
        fail = { action: 'error', status: 103, msg: _errCode[103], data: result };
        await dispatch('setLoginProcess', fail);
        return false;
      }
      if (!result.data || !result.data.mem_uuid) {
        fail = { action: 'error', status: 104, msg: _errCode[104], data: result };
        await dispatch('setLoginProcess', fail);
        return false;
      }
      // 成功
      await dispatch('setLoginProcess', { action: 'ok' });
      await dispatch('setLogger', result.data);
      // alert('登入驗證成功，redirect to: ' + state.redirect_success);
      router.push(state.redirect_success);
      return;
    } catch (error) {
      fail = { action: 'error', status: 105, msg: _errCode[105], data: error };
      await dispatch('setLoginProcess', fail);
      router.push(state.redirect_fail);
    }
  }
};
