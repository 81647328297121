import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import LoginVerify from '@util/common/loginVerify.js';

Vue.use(VueRouter);

const routes = [
  {
    // index
    path: '/',
    redirect: '/home',
    meta: {
      // requiresOAuth: true, // 是否驗證OAuth
      menuShow: false,
      menuLabel: ''
    }
  },
  {
    // logout
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout'),
    meta: {
      requestOAuth: false,
      menuShow: false,
      menuLabel: '登出',
      icon: 'mdi-logout' // mdi-login-variant'
    }
  },
  {
    // login
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
    meta: {
      requestOAuth: false,
      menuShow: false,
      menuLabel: '登入',
      icon: 'mdi-login-variant' // mdi-login
    }
  },
  {
    // home
    path: '/home', // start with '/' will be treated as a root path
    name: 'Home', // name of this route, when you already set default route ('/') in children, then disable this option (find '/ad' for reference)
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    // 如果import 寫在最上方，而非routes 定義(定義寫法入下列)，generate chunk all in app.[hash].js for this route
    // component: Home,
    meta: {
      // requestOAuth: true, // 是否驗證OAuth
      menuShow: true, // 是否在導航欄中顯示
      menuLabel: '首頁', // 導航欄中顯示的名稱
      subLabel: 'Home',
      isCollapse: false, // 導航欄中是否預設子選項開合狀態
      icon: 'mdi-home-city' // 'mdi-file-chart' // 導航欄選項的icon圖示，house-door-fill
    } /**
     * children for nested paths and path as start with / will be treated as a root path
     * make sure vue.config.js set 'publicPath' is '/' not './' or ''
     * /
    children: [
      {...},
      {...}
    ]
    */
  },
  {
    // about
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
    meta: {
      // requestOAuth: true,
      menuShow: true,
      menuLabel: '關於我們',
      subLabel: 'About Us',
      // lv: 0, // 階層
      isCollapse: false, // 子選單是否已展開
      icon: 'mdi-blur-linear'
    }
  },
  {
    // service
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "service" */ '../views/Service'),
    meta: {
      // requestOAuth: true,
      menuShow: true,
      menuLabel: '服務項目',
      subLabel: 'Services',
      // lv: 0, // 階層
      isCollapse: false, // 子選單是否已展開
      icon: 'mdi-handshake-outline'
    }
  },
  {
    path: '/distribution',
    name: 'Distribution',
    component: () => import(/* webpackChunkName: "distribution" */ '../views/Distribution'),
    meta: {
      // requestOAuth: true,
      menuShow: true,
      menuLabel: '據點分佈',
      subLabel: 'Distributions',
      // lv: 0, // 階層
      isCollapse: false, // 子選單是否已展開
      icon: 'mdi-map-marker-radius-outline'
    }
  },
  {
    // contact
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "demand" */ '../views/Contact'),
    meta: {
      // requestOAuth: true,
      menuShow: true,
      menuLabel: '聯絡我們',
      subLabel: 'Contact Us',
      // lv: 0, // 階層
      isCollapse: false, // 子選單是否已展開
      icon: 'mdi-card-account-phone-outline'
    }
  },
  {
    // register
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register'),
    meta: {
      requestOAuth: false,
      menuShow: false,
      menuLabel: '會員註冊',
      subLabel: 'Apply Register'
      // lv: 0, // 階層
      // isCollapse: false, // 子選單是否已展開
      // icon: 'mdi-card-account-phone-outline'
    },
    children: [
      {
        path: '/register/verify',
        name: 'RegisterVerify',
        component: () => import(/* webpackChunkName: "register_verify" */ '../views/Register/verify'),
        meta: {
          requestOAuth: false,
          menuShow: false,
          menuLabel: '帳號驗證開通',
          icon: ''
        }
      }
    ]
  },
  /**
   * 以下需要帳密驗證後使用
   * 1. 會員中心
   * 2. 裝機需求管理
   */
  {
    // member
    path: '/member',
    name: 'Member',
    component: () => import(/* webpackChunkName: "member" */ '../views/Member'),
    meta: {
      requestOAuth: true,
      menuShow: false,
      menuLabel: '會員中心',
      // lv: 0, // 階層
      isCollapse: false, // 子選單是否已展開
      icon: 'mdi-account-group-outline'
    },
    children: [
      {
        path: '/member/main',
        name: 'MemberMain',
        component: () => import(/* webpackChunkName: "member_main" */ '../views/Member/main'),
        meta: {
          requestOAuth: true,
          menuShow: false,
          menuLabel: '會員中心-主頁',
          icon: ''
        }
      },
      {
        path: '/member/profile',
        name: 'MemberProfile',
        component: () => import(/* webpackChunkName: "member_profile" */ '../views/Member/profile'),
        meta: {
          requestOAuth: true,
          menuShow: false,
          menuLabel: '會員中心-個人資料',
          icon: ''
        }
      }
    ]
  },
  {
    // installation
    path: '/installation',
    name: 'Installation',
    component: () => import(/* webpackChunkName: "installation" */ '../views/Installation'),
    meta: {
      requestOAuth: true,
      menuShow: false,
      menuLabel: '裝機需求單管理',
      subLabel: 'Apply Register'
      // lv: 0, // 階層
      // isCollapse: false, // 子選單是否已展開
      // icon: 'mdi-card-account-phone-outline'
    },
    children: [
      {
        path: '/installation/list',
        name: 'InstallationList',
        component: () => import(/* webpackChunkName: "installation_list" */ '../views/Installation/list'),
        meta: {
          requestOAuth: true,
          menuShow: false,
          menuLabel: '需求單一覽表',
          icon: ''
        }
      },
      {
        path: '/installation/apply',
        name: 'InstallationApply',
        component: () => import(/* webpackChunkName: "installation_apply" */ '../views/Installation/apply'),
        meta: {
          requestOAuth: true,
          menuShow: false,
          menuLabel: '申請裝機',
          icon: ''
        }
      }
    ]
  },
  {
    // 當 url path 不符合 router 表的時候，預設轉址到 首頁，順序一定要最後面
    path: '/*',
    redirect: '/home'
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 路由攔截token驗證;
router.beforeEach((to, from, next) => {
  // console.log('[router-beforeEach] passed ...... to / from');
  // console.log(to);
  // console.log(from);
  console.log(localStorage);
  const authRequired = 'requestOAuth' in to.meta ? to.meta.requestOAuth : false;
  const doLogin = to.name === 'Login' || to.name === 'LoginCRM';
  // const doLogout = to.name === 'Logout';
  const _login = LoginVerify.check_login();
  // const isLogin = !!localStorage.getItem('accessToken') && 'maUser' in localStorage;
  // const maUser = isLogin ? JSON.parse(localStorage.getItem('maUser')) : null;
  // // const maProfile = isLogin ? localStorage.getItem('maProfile') : null; // member or user
  // // 是否逾期
  // let isExpired = false;
  // const currTS = Math.floor(Date.now() / 1000);
  // if ('maUser' in localStorage && maUser.authentication !== null && maUser.authentication.access_token !== null && maUser.authentication.access_token.timestamp !== null) {
  //   isExpired = currTS >= maUser.authentication.access_token.timestamp ? true : isExpired;
  // }

  // console.log(`*authRequired: ${authRequired}，profile: ${maProfile}，doLogin: ${doLogin}，isLogin: ${isLogin}，doLogout: ${doLogout}，isExpired: ${isExpired}`);
  // console.log(maUser);

  // 需先登入才能使用的路由
  if (authRequired) {
    if (!_login.isLogin) { // 尚未登入
      next('/login');
    }
    if (_login.isExpired) { // token已逾期
      next('/logout');
    }
  }

  // 目的頁是登入頁
  if (doLogin) {
    if (_login.isLogin) { // 已是登入狀態 (依身分自動轉向登入後首頁)
      next('/member/main');
    }
  }

  // console.log('[router-beforeEach] passed ...... ');
  next();
});

export default router;
